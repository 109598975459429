import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { boxShadows, colors, grayScales, gradations } from 'styles/variables';
import { dayOfWeek } from 'utils/tools';

const ArticleCardStyled = styled.div`
  .article-card {
    background-color: ${colors.white};
    box-shadow: ${boxShadows.article};
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 173px;
    margin-bottom: 10px;
    transition: 0.2s;
    width: 100%;

    ${mediaDown(
    'desktop',
    css`
        height: 104px;
      `
  )}

    ${mediaDown(
    'tablet',
    css`
        display: inline-block;
        height: auto;
      `
  )}

    &:hover {
      opacity: 0.75;
    }

    &__image {
      min-width: 400px;
      overflow: hidden;
      position: relative;
      width: 400px;

      &--empty {
        display: flex;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        background-image: ${gradations.emptyArticleImage};
        height: 100%;
        width: 100%;

        ${fontSize(30)}
      }

      ${mediaDown(
    'desktop',
    css`
          min-width: 240px;
          width: 240px;
        `
  )}

      ${mediaDown(
    'tablet',
    css`
          width: 100%;
        `
  )}

      img {
        width: 100%;
      }
    }

    &__content {
      box-sizing: border-box;
      height: 100%;
      padding: 20px 16px;
      width: 100%;

      ${mediaDown('desktop', css`
        padding: 10px 16px;
      `)}

      ${mediaDown('tablet', css`
        height: 114px;
      `)}

      &-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
      }

      &--header {
        h2 {
          ${fontSize(20)}
          color: ${grayScales.gs900};
          font-weight: 600;
          overflow: hidden;
          position: absolute;
          text-overflow: ellipsis;
          top: 0;
          white-space: nowrap;
          width: 100%;
          margin: 0;

          ${mediaDown('desktop', css`
            ${fontSize(18)}
          `)}
        }
      }

      &--body {
        bottom: 0;
        position: absolute;
        width: 100%;
      }
    }

    &-content-body {
      width: 100%;

      &__detail {
        ${fontSize(16)}
        color: ${grayScales.gs400};
        margin: 0;

        ${mediaDown('desktop', css`
          ${fontSize(12)}
          margin: 5px 0;
        `)}
      }

      &__footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--category {
          ${fontSize(16)}
          color: ${colors.primary};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          ${mediaDown(
    'desktop',
    css`
              ${fontSize(14)}
            `
  )}
        }

        &--date {
          color: ${grayScales.gs400};
          font-weight: 200;
        }
      }
    }

    &-date {
      &__date {
        ${fontSize(20)}

        ${mediaDown(
    'desktop',
    css`
            ${fontSize(16)}
          `
  )}
      }

      &__day {
        ${fontSize(12)}
      }
    }

    &-new {
      ${fontSize(14)}
      background-color: red;
      box-sizing: border-box;
      color: ${colors.white};
      font-wight: 500;
      padding: 30px 40px 10px 40px;
      position: absolute;
      transform: rotate(-45deg) translate(-20px, -40px);
      width: 100px;
    }
  }
`;

type ArticleCardType = {
  title: string;
  categoryName: string;
  path: string;
  thumbnailUrl: string;
  date: string;
};

const ArticleCard = (props: ArticleCardType) => {
  const router = useRouter(),
    currentDate = new Date();
  currentDate.setDate(new Date().getDate() - 7);

  return (
    <ArticleCardStyled>
      <div className='article-card' onClick={() => router.push(props.path)}>
        <div className='article-card__image'>
          {currentDate.getTime() > new Date(props.date).getTime() ? '' : <div className='article-card-new'>NEW</div>}
          {
            props.thumbnailUrl === null ? (
              <div className='article-card__image--empty'>
                {props.date}
              </div>
            ) : (
              <img src={props.thumbnailUrl} alt='article_image' />
            )
          }
        </div>
        <div className='article-card__content'>
          <div className='article-card__content-wrapper'>
            <div className='article-card__content--header'>
              <h2>{props.title}</h2>
            </div>
            <div className='article-card__content--body'>
              <div className='article-card-content-body'>
                <div className='article-card-content-body__detail'>詳細はこちら</div>
                <div className='article-card-content-body__footer'>
                  <div className='article-card-content-body__footer--category'>{props.categoryName}</div>
                  <div className='article-card-content-body__footer--date'>
                    <div className='article-card-date'>
                      <span className='article-card-date__date'>{props.date}</span>
                      <span className='article-card-date__day'>({dayOfWeek(props.date)})</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ArticleCardStyled>
  );
};

export default ArticleCard;

export type { ArticleCardType }
