import ArticleCard from 'components/pages/article/ArticleCard';
import { useFlashMessageState } from 'contexts/FlashMessageContext';
import { useApiClientV2 } from 'hooks/useApiClient';
import Link from 'next/link';
import { useEffect } from 'react';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { grayScales } from 'styles/variables';
import { ArticleHomeRequest } from 'types/apiRequests';
import { ArticleIndexHomeResultType } from 'types/apiResponseTypes';

const HomeArticleListStyled = styled.div`
  .home-articles {
    margin: 30px 0;

    &__category {
      ${fontSize(20)}
      font-weight: 600;
      margin: 20px 0;
    }

    &__article {
    }

    &__list {
      ${fontSize(14)}
      margin: 15px 0;
      text-align: right;

      a {
        color: ${grayScales.gs900};
        text-decoration: none;
      }
    }
  }
`;

const HomeArticleList = () => {
  const { data: articleCategories } = useApiClientV2<ArticleIndexHomeResultType>(new ArticleHomeRequest);
  const { setFlashMessage } = useFlashMessageState();

  useEffect(() => {
    if (articleCategories.requireMcnEntryNotification) {
      setFlashMessage({
        color: 'primary',
        message: `YouTubeチャンネルの収益化がお済みの方で、まだMCNにご参加いただいていない方は、<a href="/apply/monetize">こちら</a>からMCN参加申請を行ってください。<br>
MCNについては<a href="https://creators.uuum.jp/article/news/list/8549">こちら</a>をご覧ください。`,
      });
    }
  }, [articleCategories.requireMcnEntryNotification])

  return (
    <HomeArticleListStyled>
      {articleCategories.articles.map((article, i) => (
        <div key={i} className='home-articles'>
          <div className='home-articles__category'>{article.categoryName}</div>
          <div className='home-articles__article'>
            {article.list.map((item, j) => (
              <ArticleCard
                key={j}
                title={item.title}
                categoryName={article.categoryName}
                path={item.path}
                thumbnailUrl={item.thumbnailUrl}
                date={item.date}
              />
            ))}
          </div>
          <div className='home-articles__list'>
            <Link href={article.listPath}>{`＞＞${article.categoryName}一覧へ`}</Link>
          </div>
        </div>
      ))}
    </HomeArticleListStyled>
  );
}

export default HomeArticleList;
