import { getAppLayout } from 'components/layouts/AppLayout';
import HomeArticleList from 'components/pages/home/HomeArticleList';
import HomeCarousel from 'components/pages/home/HomeCarousel';
import { Suspense } from 'react';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { NextPageWithLayout } from './_app';

const HomeRoot = styled.div`
  padding: 0 40px;

  ${mediaDown(
    'desktop',
    css`
      padding: 0;
    `
  )}
  `,
  Home: NextPageWithLayout = () => (
    <HomeRoot>
      <Suspense fallback={ null }>
        <HomeCarousel />
      </Suspense>
      <Suspense fallback={ null }>
        <HomeArticleList />
      </Suspense>
    </HomeRoot>
  );

Home.getLayout = getAppLayout;

export default Home;
