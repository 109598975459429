import { useApiClientV2 } from 'hooks/useApiClient';
import Link from 'next/link';
import Carousel from 'nuka-carousel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DashboardRequest } from 'types/apiRequests';
import { DashbordIndexResultType } from 'types/apiResponseTypes';

const CarouselImageStyled = styled.div`
  img {
    width: 100%;
  }
`;

const HomeCarousel = () => {
  const { data: dashboards } = useApiClientV2<DashbordIndexResultType>(new DashboardRequest);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  return (
    <Carousel
      autoplay={true}
      autoplayInterval={3000}
      slidesToShow={width < 840 ? 1 : 3}
      wrapAround={true}
      cellAlign='center'
    >
      {dashboards.map((item: { path: string; image: string }, i: number) => (
        <CarouselImageStyled key={i}>
          <Link href={item.path} passHref>
            <img src={item.image} alt='carousel_image' />
          </Link>
        </CarouselImageStyled>
      ))}
    </Carousel>
  );
}

export default HomeCarousel;
