type ObjType = {
  [key: string]: string;
};

const objFilter = (
  originObj: ObjType,
  // eslint-disable-next-line no-unused-vars
  filter: (value: string) => boolean
): object => Object.keys(originObj)
  .filter(filter)
  .reduce((obj: ObjType, value: string) => {
    obj[value] = originObj[value];
    return obj;
  }, {});

const dayOfWeek = (date: string | Date): string => {
  const days: string[] = ['日', '月', '火', '水', '木', '金', '土'];

  return days[new Date(date).getDay()];
};

const arrayChunk = <T>(arr: T[], size: number) => arr.reduce(
  (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
  [] as T[][]
)

export { objFilter, dayOfWeek, arrayChunk };
