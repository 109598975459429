import { HTTP_OK } from 'constants/httpStatusConstants';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { ApiRequestInterface } from 'types/apiRequests';
import { ApiResponseType } from 'types/apiResponseTypes';
import { ApiClient } from 'utils/ApiClient';
import { ApiClientError } from 'utils/ApiClientError';

const customFetcherV2 = <T, U extends ApiRequestInterface>(request: U): Promise<T> => {
  const apiClient = new ApiClient<T>();
  return apiClient.requestV2(request).then(
    (res: ApiResponseType<T>) => {
      if (res.status !== HTTP_OK) {
        throw new ApiClientError(res);
      }
      return res.result as T;
    }
  );
}

export const useApiClientV2 = <T, U extends ApiRequestInterface = any>(request: U) => {
  const router = useRouter();

  if (!router.isReady) {
    throw Promise.resolve();
  }

  const fetcherWrapper = (): Promise<T> => customFetcherV2(request);
  return useSWR(
    [request.getPath(), { ...request }],
    fetcherWrapper,
    {
      suspense: true,
      errorRetryInterval: 3000,
      revalidateOnMount: true,
    });
}
